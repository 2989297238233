import React, { useState } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  SimpleGrid,
  Checkbox,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FcBusiness, FcIdea, FcPositiveDynamic } from 'react-icons/fc';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);
const MotionText = motion(Text);

interface PresentationProps {
  onDiscoverClick: () => void;
}

const Presentation: React.FC<PresentationProps> = ({ onDiscoverClick }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [formData, setFormData] = useState({
    firstName: '',
    companyName: '',
    phone: '',
    date: '',
    time: '',
    heardFrom: '',
    presentation: false,
  });

  const [errors, setErrors] = useState({
    firstName: '',
    companyName: '',
    phone: '',
    date: '',
    time: '',
    heardFrom: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      const { checked } = e.target as HTMLInputElement;
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = { firstName: '', companyName: '', phone: '', date: '', time: '', heardFrom: '' };

    if (!formData.firstName) {
      newErrors.firstName = 'Le prénom est requis.';
      valid = false;
    }
    if (!formData.phone) {
      newErrors.phone = 'Le numéro de téléphone est requis.';
      valid = false;
    }
    if (!formData.date) {
      newErrors.date = 'La date est requise.';
      valid = false;
    }
    if (!formData.time) {
      newErrors.time = "L'heure est requise.";
      valid = false;
    }
    if (!formData.heardFrom) {
      newErrors.heardFrom = 'Ce champ est requis.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      toast({
        title: 'Erreur de validation',
        description: 'Veuillez corriger les erreurs dans le formulaire.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Handle form submission (e.g., via emailjs or another method)

    toast({
      title: 'Demande envoyée',
      description: 'Votre demande a été envoyée avec succès. Nous vous contacterons bientôt.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    setFormData({
      firstName: '',
      companyName: '',
      phone: '',
      date: '',
      time: '',
      heardFrom: '',
      presentation: false,
    });
    onClose();
  };

  return (
    <MotionBox
      textAlign="center"
      py={{ base: 6, md: 10 }}
      px={{ base: 4, md: 6 }}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 2.3 }}
    >
      <VStack spacing={{ base: 4, md: 6 }}>
        <MotionText
          fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
          fontWeight="bold"
          color="gray.800"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 2.3 }}
        >
          ProtectoSystem - Votre solution digitale
        </MotionText>
        <MotionText
          fontSize={{ base: 'md', md: 'lg' }}
          color="gray.500"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          ProtectoSystem est une entreprise innovante qui révolutionne la gestion des missions de
          sécurité et de secours. Notre plateforme logicielle unique vous permet de simplifier vos
          opérations et d'offrir une meilleure expérience à vos équipes et à vos clients.
        </MotionText>

        {/* Cards Section */}
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} mt={8}>
          {[
            {
              icon: <FcBusiness style={{ width: '100%', height: '100%' }} />,
              title: 'Notre mission',
              description:
                'Permettre à nos clients de se concentrer sur leur cœur de métier en simplifiant la gestion de leurs opérations de sécurité et de secours.',
            },
            {
              icon: <FcIdea style={{ width: '100%', height: '100%' }} />,
              title: 'Notre solution',
              description:
                'Une plateforme intuitive et puissante, conçue par des experts pour optimiser vos processus.',
            },
            {
              icon: <FcPositiveDynamic style={{ width: '100%', height: '100%' }} />,
              title: 'Nos avantages',
              description:
                'Gain de temps, meilleure coordination, réduction des coûts et satisfaction clients.',
            },
          ].map((card, index) => (
            <MotionBox
              key={index}
              bg="white"
              color="black"
              p={6}
              borderRadius="md"
              boxShadow="lg"
              textAlign="center"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <Box mx="auto" width="40px" height="40px" mb={4}>
                {card.icon}
              </Box>
              <Text fontWeight="bold" fontSize="xl">
                {card.title}
              </Text>
              <Text fontSize="md">{card.description}</Text>
            </MotionBox>
          ))}
        </SimpleGrid>

        {/* Call to Action Buttons */}
        <HStack
          spacing={{ base: 2, md: 4 }}
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          mt={8}
        >
          <MotionBox
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Button
              colorScheme="blue"
              size="xl"
              fontSize="lg"
              fontWeight="bold"
              py={8}
              px={10}
              onClick={onOpen}
            >
              Planifiez un appel
            </Button>
          </MotionBox>
          <MotionBox
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Button
              variant="outline"
              colorScheme="blue"
              size="xl"
              fontSize="lg"
              fontWeight="bold"
              py={8}
              px={10}
              onClick={onDiscoverClick}
            >
              Découvrez nos produits
            </Button>
          </MotionBox>
        </HStack>
      </VStack>

      {/* Modal for scheduling a call */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">Planifiez un appel avec nous !</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textAlign="center" mb={6}>
              Découvrez en 5-10 minutes comment nos solutions peuvent améliorer vos processus grâce à l'automatisation.
            </Text>
            <VStack spacing={4}>
              <FormControl isInvalid={!!errors.firstName} isRequired>
                <FormLabel>Nom</FormLabel>
                <HStack>
                  <Input
                    name="firstName"
                    placeholder="Prénom"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <Input
                    name="companyName"
                    placeholder="Nom de l'entreprise"
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </HStack>
                {errors.firstName && <FormErrorMessage>{errors.firstName}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={!!errors.phone} isRequired>
                <FormLabel>Indicatif & numéro de téléphone</FormLabel>
                <Input
                  name="phone"
                  type="tel"
                  placeholder="Votre numéro de téléphone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {errors.phone && <FormErrorMessage>{errors.phone}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={!!errors.date} isRequired>
                <FormLabel>Date & Heure</FormLabel>
                <HStack>
                  <Input
                    name="date"
                    type="date"
                    value={formData.date}
                    onChange={handleChange}
                  />
                  <Select
                    name="time"
                    placeholder="Heure (UTC+1)"
                    value={formData.time}
                    onChange={handleChange}
                  >
                    <option value="8:00">08:00</option>
                    <option value="9:00">09:00</option>
                    <option value="10:00">10:00</option>
                  </Select>
                </HStack>
                {errors.date && <FormErrorMessage>{errors.date}</FormErrorMessage>}
              </FormControl>
              <FormControl isInvalid={!!errors.heardFrom} isRequired>
                <FormLabel>Où avez-vous entendu parler de nous ?</FormLabel>
                <Select
                  name="heardFrom"
                  placeholder="Sélectionnez une option"
                  value={formData.heardFrom}
                  onChange={handleChange}
                >
                  <option value="web">Site Web</option>
                  <option value="friend">Un ami</option>
                  <option value="ad">Publicité</option>
                </Select>
                {errors.heardFrom && <FormErrorMessage>{errors.heardFrom}</FormErrorMessage>}
              </FormControl>
              <FormControl>
                <Checkbox
                  name="presentation"
                  isChecked={formData.presentation}
                  onChange={handleChange}
                >
                  Je souhaite une présentation de ProtectoSystem
                </Checkbox>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" size="lg" width="full" onClick={handleSubmit}>
              Appelez-moi
            </Button>
          </ModalFooter>
          <Text textAlign="center" mt={4} fontSize="sm" color="gray.500" px={4}>
            Vous pouvez également nous contacter au <Text as="span" color="blue.600">07 73 02 05 38</Text> pendant les heures de bureau (fuseau horaire CET). Si la date et l'heure de l'appel ne sont pas précisées, nous vous contacterons dans les 24 heures.
          </Text>
        </ModalContent>
      </Modal>
    </MotionBox>
  );
};

export default Presentation;
