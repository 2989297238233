import React from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  HStack,
  SimpleGrid,
  Tag,
  Flex,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);
const MotionText = motion(Text);

interface FeaturesSectionProps {
  contactRef: React.RefObject<HTMLDivElement>;
  scrollToSection: (section: React.RefObject<HTMLDivElement>) => void;
}

const FeaturesSection: React.FC<FeaturesSectionProps> = ({ contactRef, scrollToSection }) => {
  return (
    <MotionBox
      textAlign="center"
      py={{ base: 6, md: 10 }}
      px={{ base: 4, md: 6 }}
      bg="white"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <VStack spacing={{ base: 4, md: 6 }}>
        {/* Title */}
        <MotionText
          fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
          fontWeight="bold"
          color="gray.800"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Découvrez nos fonctionnalités
        </MotionText>

        {/* Feature Cards */}
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={8} mt={8} width="100%">
          {[
            { number: 1, text: 'Planification des missions' },
            { number: 2, text: 'Suivi en temps réel' },
            { number: 3, text: 'Coordination des équipes' },
            { number: 4, text: "Rapports d'activité" },
          ].map((feature, index) => (
            <MotionBox
              key={index}
              bg="gray.50"
              p={6}
              borderRadius="md"
              boxShadow="lg"
              textAlign="center"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <Text fontWeight="bold" fontSize="2xl" color="blue.500">
                {feature.number}
              </Text>
              <Text fontSize="lg" color="gray.800">
                {feature.text}
              </Text>
            </MotionBox>
          ))}
        </SimpleGrid>

        {/* Action Buttons */}
        <HStack
          spacing={{ base: 2, md: 4 }}
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          mt={8}
        >
          <MotionBox
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button colorScheme="blue" size="lg" width={{ base: 'full', sm: 'auto' }} onClick={() => scrollToSection(contactRef)}>
              En savoir plus
            </Button>
          </MotionBox>
          <MotionBox
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <Button
              variant="outline"
              colorScheme="blue"
              size="lg"
              width={{ base: 'full', sm: 'auto' }}
              onClick={() => scrollToSection(contactRef)}
            >
              Contactez-nous
            </Button>
          </MotionBox>
        </HStack>

        {/* Tags */}
        <Flex justifyContent="center" mt={4} wrap="wrap">
          {[
            { text: 'INNOVANT', color: 'purple' },
            { text: 'EFFICACE', color: 'green' },
            { text: 'COLLABORATIF', color: 'orange' },
          ].map((tag, index) => (
            <MotionBox
              key={index}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <Tag size="lg" colorScheme={tag.color} m={2}>
                {tag.text}
              </Tag>
            </MotionBox>
          ))}
        </Flex>
      </VStack>
    </MotionBox>
  );
};

export default FeaturesSection;
