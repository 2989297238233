import React, { useRef } from 'react';
import {
  Box,
  ChakraProvider,
  VStack,
  HStack,
  Button,
  useBreakpointValue,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
  Text,
  Container,
} from '@chakra-ui/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FcPhone } from 'react-icons/fc';
import theme from './theme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Presentation from './components/Presentation';
import ContactUs from './components/ContactUs';
import FeaturesSection from './components/FeaturesSection';
import AboutSection from './components/AboutSection';
import ObjectivesSection from './components/ObjectivesSection';
import TeamSection from './components/TeamSection';
import MissionSection from './components/MissionSection';
import SolutionSection from './components/SolutionSection';
import KeyFeaturesSection from './components/KeyFeaturesSection';
import ClientBenefitsSection from './components/ClientBenefitsSection';
import ServiceOfferSection from './components/ServiceOfferSection';
import CGU from './CGU';
import PrivacyPolicy from './components/PrivacyPolicy';

const App: React.FC = () => {
  const presentationRef = useRef<HTMLDivElement>(null);
  const demonstrationRef = useRef<HTMLDivElement>(null);
  const infoSectionsRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (section: React.RefObject<HTMLDivElement>) => {
    if (section.current) {
      const yOffset = -100;
      const y = section.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <ChakraProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey="6LfXqSIqAAAAAFPrGZUy_ZU1R4etKGN2KksZR1Ii">
        <Router>
          {/* Header */}
          <Box position="fixed" top="0" width="100%" bg="white" zIndex="1000" boxShadow="sm">
            <Flex
              justify="space-between"
              align="center"
              padding={4}
              mx="auto"
              maxWidth="1200px"
              width="100%"
            >
              <HStack spacing={4}>
                <Image
                  src="/logoprotecto.png"
                  alt="Logo Protecto"
                  boxSize="50px"
                  borderRadius="full"
                  boxShadow="md"
                  onClick={() => scrollToSection(presentationRef)}
                  cursor="pointer"
                />
              </HStack>

              {isSmallScreen ? (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Ouvrir le menu"
                    icon={<GiHamburgerMenu />}
                    variant="outline"
                    bg="white"
                    borderRadius="full"
                    boxShadow="md"
                  />
                  <MenuList borderRadius="md" boxShadow="lg" mt={2}>
                    <MenuItem>
                      <HStack spacing={2}>
                        <FcPhone size={24} />
                        <Text fontWeight="bold" color="gray.800" fontSize="lg">
                          07 87 78 79 20
                        </Text>
                      </HStack>
                    </MenuItem>
                    <MenuItem onClick={() => scrollToSection(presentationRef)}>Présentation</MenuItem>
                    <MenuItem onClick={() => scrollToSection(demonstrationRef)}>Équipe dirigeante</MenuItem>
                    <MenuItem onClick={() => scrollToSection(infoSectionsRef)}>Notre solution logicielle</MenuItem>
                    <MenuItem onClick={() => scrollToSection(contactRef)}>Contactez-nous</MenuItem>
                    <MenuItem onClick={() => scrollToSection(infoSectionsRef)}>Nos produits</MenuItem>
                    <MenuItem onClick={() => scrollToSection(contactRef)}>Contact</MenuItem>
                  </MenuList>
                </Menu>
              ) : (
                <HStack spacing={8} align="center">
                  <Button variant="link" onClick={() => scrollToSection(presentationRef)}>
                    Présentation
                  </Button>
                  <Button variant="link" onClick={() => scrollToSection(demonstrationRef)}>
                    Équipe dirigeante
                  </Button>
                  <Button variant="link" onClick={() => scrollToSection(infoSectionsRef)}>
                    Notre solution logicielle
                  </Button>
                  <Button variant="link" onClick={() => scrollToSection(contactRef)}>
                    Contact
                  </Button>
                  <HStack spacing={2}>
                    <FcPhone size={24} />
                    <Text fontWeight="bold" color="gray.800" fontSize="lg">
                      07 87 78 79 20
                    </Text>
                  </HStack>
                  <HStack spacing={4}>
                    <Button
                      bg="white"
                      variant="outline"
                      colorScheme="blue"
                      borderRadius="full"
                      fontSize={{ base: 'md', md: 'lg' }}
                      height="60px"
                      px={{ base: 6, md: 10 }}
                      py={{ base: 4, md: 8 }}
                      onClick={() => scrollToSection(infoSectionsRef)}
                    >
                      Nos produits
                    </Button>
                    <Button
                      colorScheme="blue"
                      bg="blue.500"
                      color="white"
                      borderRadius="full"
                      fontSize={{ base: 'md', md: 'lg' }}
                      height="60px"
                      px={{ base: 6, md: 10 }}
                      py={{ base: 4, md: 8 }}
                      onClick={() => scrollToSection(contactRef)}
                    >
                      Contact
                    </Button>
                  </HStack>
                </HStack>
              )}
            </Flex>
          </Box>

          {/* Content Sections */}
          <VStack spacing={0} mt="120px" align="stretch">
            {/* Section 1: Présentation */}
            <Box
              ref={presentationRef}
              bgGradient="linear(to-r, blue.100, blue.200)"
              py={12}
              px={{ base: 6, md: 10 }}
            >
              <Container maxW="1200px">
                <Presentation onDiscoverClick={() => scrollToSection(demonstrationRef)} />
              </Container>
            </Box>

            {/* Section 2: Fonctionnalités */}
            <Box bgGradient="linear(to-r, gray.100, gray.200)" py={12} px={{ base: 6, md: 10 }}>
              <Container maxW="1200px">
              <FeaturesSection contactRef={contactRef} scrollToSection={scrollToSection} />
              </Container>
            </Box>

            {/* Section 3: À propos */}
            <Box bgGradient="linear(to-r, white, gray.100)" py={12} px={{ base: 6, md: 10 }}>
              <Container maxW="1200px">
                <AboutSection />
              </Container>
            </Box>

            {/* Section 4: Objectifs */}
            <Box
              bgGradient="linear(to-r, blue.200, blue.300)"
              py={12}
              px={{ base: 6, md: 10 }}
            >
              <Container maxW="1200px">
                <ObjectivesSection />
              </Container>
            </Box>

            {/* Section 5: Équipe dirigeante */}
            <Box               ref={demonstrationRef} bgGradient="linear(to-r, gray.200, gray.300)" py={12} px={{ base: 6, md: 10 }}>
              <Container maxW="1200px">
                <TeamSection />
              </Container>
            </Box>

            {/* Section 6: Mission */}
            <Box bgGradient="linear(to-r, white, gray.50)" py={12} px={{ base: 6, md: 10 }}>
              <Container maxW="1200px">
                <MissionSection />
              </Container>
            </Box>

            {/* Section 7: Solutions */}
            <Box
              ref={infoSectionsRef}
              bgGradient="linear(to-r, white, blue.100)"
              py={12}
              px={{ base: 6, md: 10 }}
            >
              <Container maxW="1200px">
                <SolutionSection />
              </Container>
            </Box>

            {/* Section 8: Key Features */}
            <Box bgGradient="linear(to-r, blue.300, blue.400)" py={12} px={{ base: 6, md: 10 }}>
              <Container maxW="1200px">
                <KeyFeaturesSection />
              </Container>
            </Box>

            {/* Section 9: Client Benefits */}
            <Box bgGradient="linear(to-r, gray.50, gray.100)" py={12} px={{ base: 6, md: 10 }}>
              <Container maxW="1200px">
                <ClientBenefitsSection />
              </Container>
            </Box>

            {/* Section 10: Services */}
            <Box bgGradient="linear(to-r, gray.200, gray.300)" py={12} px={{ base: 6, md: 10 }}>
              <Container maxW="1200px">
                <ServiceOfferSection />
              </Container>
            </Box>

            {/* Section 11: Contact */}
            <Box
              ref={contactRef}
              bgGradient="linear(to-r, blue.400, blue.500)"
              py={12}
              px={{ base: 6, md: 10 }}
            >
              <Container maxW="1200px">
                <ContactUs />
              </Container>
            </Box>
          </VStack>

          {/* Routes */}
          <Routes>
            <Route path="/cgu" element={<CGU />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
      </GoogleReCaptchaProvider>
    </ChakraProvider>
  );
};

export default App;
